$COMPANY:actusite;
.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(235, 235, 235, 0.7);
    backdrop-filter: blur(4px);
    z-index: 999;
    cursor: pointer;
    &-container {
        position: fixed;
        top: 30%;
        width: 100%;
        text-align: center;
        &__icon {
            top: 50%;
            right: 50%;
            i.fa-blank {
                color: white;
            }
            i.fa-green {
                color: green;
            }
            i.fa-red {
                color: red;
            }
        }
        &__label {
            color: white;
            font-size: 24px;
            display: inherit;
        }
    }
}
.overlay-content {
    display: none;
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    cursor: pointer;
    &-container {
        position: absolute;
        top: 30%;
        width: 100%;
        text-align: center;
        &__icon {
            i.fa-blank {
                color: white;
            }
            i.fa-green {
                color: green;
            }
            i.fa-red {
                color: red;
            }
        }
        &__label {
            color: white;
            font-size: 16px;
            display: inherit;
        }
    }
}

